import React from "react";
import SignupForm from "@views/signup";

const Signup = () => {
  return (
    <>
      <SignupForm />
    </>
  );
};

export default Signup;
